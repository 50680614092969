import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import "./permisstion"

import ECharts from 'vue-echarts'
import ElementUI from 'element-ui';

//import 'element-ui/lib/theme-chalk/index.css';
import './styles/element-variables.scss';
import '../src/assets/css/common.css';
import 'animate.css';

import {message} from "../src/utils/reserMessage"

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

import MyNav from '../src/components/School/MyNav.vue'
import MyFooter from '../src/components/School/MyFooter.vue'
import CMyNav from '../src/components/Company/CMyNav.vue'
import CMyFooter from '../src/components/Company/CMyFooter.vue'

Vue.component('MyNav', MyNav);
Vue.component('MyFooter', MyFooter);
Vue.component('v-chart', ECharts)
Vue.component('CMyNav', CMyNav) //企业端
Vue.component('CMyFooter', CMyFooter) //企业端

Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.prototype.$message = message // 重写message提示框

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
