import axios from "axios";
import {
    getToken
} from "./auth";
import router from "../router/index"
import {message} from "./reserMessage"
// 创建axios
let schooltoken = window.localStorage.getItem("school-token")

const server = axios.create({
    // baseURL: "/", 
    // baseURL:"http://118.195.145.204",
    baseURL: "https://admin.xyzp365.com", //配置请求的公共前缀
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'school-token': schooltoken,
        "server": 1,
    }
})



/* 请求拦截器 + 相应拦截器  */

server.interceptors.request.use((config) => {

    // config.headers.Authorization = getToken()
    return config

})



/* 
  统一处理相应的
*/

server.interceptors.response.use((res) => {

    /* 成功回来之后，把数据给到之前执行的  */
    // console.log(res, '提示@@@');
    if (res.data.code == 303||res.data.code == 302) {
        message.error('未登录，请登录！！！')
        window.localStorage.removeItem("school-token");
        window.localStorage.removeItem("uid");
        window.localStorage.removeItem("is_vip");
        router.push({ //未登录跳转回登录
            path:'/'
        })
    }
    // if(!res.code){

    //     if(res.code == -10){

    //         window.localStorage.removeItem("token");
    //         router.push("/index")

    //     }

    // }

    return res

})


export default server